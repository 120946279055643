import { useState } from "react";
import { calculateRealAnswer } from "../../lib/tools";
import style from "./AttemptCard.module.css";
import { useProd } from "../../hooks/useProd";

interface Props {
  imageRef: HTMLImageElement | null;
  onHoverStart: () => void;
  onHoverEnd: () => void;
  coordinates: {
    x: number;
    y: number;
  };
  deleteAttempt: () => void;
  nth: number;
  total: number;
  editDisabled: boolean;
}

export default function AttemptCard({ imageRef, onHoverStart, onHoverEnd, coordinates, deleteAttempt, nth, total, editDisabled }: Props) {
  const [deleting, setDeleting] = useState(false);
  const { realX, realY } = calculateRealAnswer(coordinates.x, coordinates.y, imageRef?.clientWidth!, imageRef?.clientHeight!);

  function handleDelete() {
    setDeleting(true);

    // Run the 0.5 sec Second animation and then delete the attempt
    setTimeout(() => {
      setDeleting(false);
      deleteAttempt();
    }, 500);
  }

  return (
    <div className={`${style.container} ${deleting && style.deleting}`} onMouseEnter={onHoverStart} onMouseLeave={onHoverEnd}>
      <div className={style.header}>
        <h4>EURO 2024 ბილეთი</h4>
        <span>
          {nth} of {total}
        </span>
      </div>

      <div className={style.content}>
        <img className={style.contentImage} src="/ticket.jpg" />

        <div className={style.coordinates}>
          <p className={style.coordinate}>{`X-${realX.toFixed(2)}`}</p>
          <p className={style.coordinate}>{`Y-${realY.toFixed(2)}`}</p>
        </div>
      </div>

      <div className={style.footer}>
        {!editDisabled && (
          <button className={style.delete} onClick={handleDelete}>
            <svg fill="none" width="668" height="668" viewBox="0 0 668 668" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M125.833 610.1L67.3334 200.667H600.667L542.167 610.1C539.896 625.986 531.971 640.52 519.847 651.033C507.723 661.547 492.214 667.334 476.167 667.333H191.833C175.786 667.334 160.277 661.547 148.153 651.033C136.029 640.52 128.104 625.986 125.833 610.1ZM634 67.3333H467.333V34C467.333 25.1594 463.821 16.6809 457.57 10.4297C451.319 4.17852 442.841 0.666626 434 0.666626H234C225.159 0.666626 216.681 4.17852 210.43 10.4297C204.179 16.6809 200.667 25.1594 200.667 34V67.3333H34C25.1595 67.3333 16.681 70.8452 10.4298 77.0964C4.17858 83.3476 0.666687 91.8261 0.666687 100.667C0.666687 109.507 4.17858 117.986 10.4298 124.237C16.681 130.488 25.1595 134 34 134H634C642.841 134 651.319 130.488 657.57 124.237C663.821 117.986 667.333 109.507 667.333 100.667C667.333 91.8261 663.821 83.3476 657.57 77.0964C651.319 70.8452 642.841 67.3333 634 67.3333Z"
                fill="currentColor"
              />
            </svg>

            <span>წაშლა</span>
          </button>
        )}
      </div>
    </div>
  );
}
