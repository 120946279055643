import { Dispatch, SetStateAction, useState } from "react";
import style from "./ClearAnswersButton.module.css";
import { useProd } from "../../hooks/useProd";
import Button from "../Button";
import { IAnswer, IUser } from "../../interfaces/user.interface";
import { clearUser } from "../../api/user.api";

interface Props {
  userId: string;
}

export function ClearAnswersButton({ userId }: Props) {
  const { shouldShowClearButton } = useProd();
  const [loading, setLoading] = useState(false);

  if(!shouldShowClearButton) return <></>;
  
  async function handleClick() {
    setLoading(true);
    try {
      const res = await clearUser(userId);

      if ("message" in res) {
        throw new Error(res.message as any);
      }

      window.location.reload();
    }
    catch(err: any) {
      console.error(err);
      alert(err?.message || "გთხოვთ თავიდან სცადოთ ლინკზე გადასვლა.");
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <Button
      text="გაწმინდე"
      theme="primary"
      onClick={handleClick}
      className={`${style.clearButton} ${loading && style.loading}`}
    />
  )
}